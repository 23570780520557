@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        font-family: "Kanit", sans-serif;
    }

    .font-outline-2 {
        -webkit-text-stroke: 2px white;
    }
}
